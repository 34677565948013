import {Lazyload} from '../Library/Lazyload.js';
import {LoadMoreEvents} from './LoadMoreEvents.js';
import {MainNavigation} from './Events/MainNavigation.js';
import {HeaderSearch} from './HeaderSearch.js';
import {L10n} from '../Library/L10n.js';
import {LazyAjaxContent} from '../Library/LazyAjaxContent.js';
import {ModalOpenAttributeEvent} from '../Library/ModalOpenAttributeEvent.js';
import {ShowMore} from '../Library/ShowMore.js';

export class Events
{
    static bindAll(parent = document.body)
    {
        Events.comparisonTables = Events.comparisonTables || [];

        // Comparison table
        for (let comparisonTableContainer of parent.querySelectorAll('.evt-comparison-search-container')) {
            import('../Library/ComparisonTable.js').then(({ComparisonTable}) => {
                Events.comparisonTables.push(new ComparisonTable(comparisonTableContainer));
            });
        }

        for (let comparisonTable of Events.comparisonTables) {
            comparisonTable.handleSiteSelection();
        }
        
        // New Toplist -card toggle info
        for (let card of parent.querySelectorAll('.card-toplist-row')) {
            for (let btn of card.querySelectorAll('.evt-card-toplist-row__info_toggle')) {
                btn.addEventListener('click', () => {
                    card.classList.toggle('card-toplist-row--open');
                });
            }

            for (let btn of card.querySelectorAll('.evt-card-toplist-row__info_toggle')) {
                btn.addEventListener('click', () => {
                    for (let content  of card.querySelectorAll('.evt-card-toplist-place-content-here')) {
                        const load = async function () {
                            const response = await fetch(content.getAttribute('data-ajax-load'));
                            const json = await response.json();

                            const contentFragment = document.createRange().createContextualFragment(json.data.html);
                            content.parentNode.replaceChild(contentFragment, content);
                        }

                        load();
                    }
                })
            }
        }

        for(let captchaForm of parent.querySelectorAll('[data-captcha="true"]')) {
            captchaForm.addEventListener('focusin', () => {
                for (let lazyCaptcha of document.querySelectorAll('.evt-lazy-captcha')) {
                    if (lazyCaptcha.hasAttribute('data-lazy-src')) {
                        lazyCaptcha.setAttribute('src', lazyCaptcha.getAttribute('data-lazy-src'));
                        lazyCaptcha.removeAttribute('data-lazy-src');
                    }
                }
            });
        }

        let requireTriggers = parent.querySelectorAll('.evt-required-if-visible');
        if (requireTriggers.length) {
            import('./Events/RequiredTrigger.js').then(({RequiredTrigger}) => {
                for (let input of requireTriggers) {
                    RequiredTrigger.bind(input);
                }
            });
        }

        // Localization
        let timeElements = parent.querySelectorAll('time');
        if (timeElements.length) {
            for (let time of timeElements) {
                L10n.localizeDatetime(time);
            }
        }
        
        // Logout
        let logoutButton = parent.querySelector('#logout');
        if (logoutButton) {
            import('./Events/Logout.js').then(({Logout}) => {
                logoutButton.addEventListener('click', () => {
                    Logout.doLogout();
                });
            });
        }

        // Toplists
        let toplists = parent.querySelectorAll('.evt-toplist');
        if (toplists.length) {
            import('../Library/Toplist/Toplist.js').then(({Toplist}) => {
                for (let toplist of toplists) {
                    new Toplist(toplist);
                }
            });
        }

        // Ajax Tooltips
        let ajaxTooltips = parent.querySelectorAll('.evt-tip-ajax[data-ajax-url]');
        if (ajaxTooltips.length) {
            import('../Library/Tooltip.js').then(({Tooltip}) => {
                for (let tooltip of ajaxTooltips) {
                    tooltip.addEventListener('mouseover', (e) => {
                        new Tooltip(e, {
                            'openDelay': 100,
                            'position': 'bottom',
                            'offset': 5,
                            'class': 'tooltip--terms',
                            'content': tooltip.hasAttribute('data-content') ? tooltip.getAttribute('data-content') : '',
                            'onOpen': (tip) => {
                                window.bo.ajax.post(tooltip.getAttribute('data-ajax-url')).onLoad(xhr => {
                                    if (xhr.status === 200) {
                                        tip.setContent(xhr.responseText, false);
                                    } else {
                                        console.warn('Tooltip error while loading ajax content');
                                    }
                                }).onError(() => {
                                    console.warn('Tooltip error while loading ajax content');
                                });
                            }
                        });
                    });
                }
            });
        }

        // Draggable
        let draggableLists = parent.querySelectorAll('.evt-draggable-list');
        if (draggableLists.length) {
            import('../Library/Draggable.js').then(({Draggable}) => {
                for (let draggable of draggableLists) {
                    new Draggable(draggable, {
                        'container': '.fieldset',
                        'dragEndFunction': null
                    });
                }
            });
        }

        // Picture uploads
        let pictureUploads = parent.querySelectorAll('.evt-picture-upload');
        if (pictureUploads.length) {
            import('./PictureUpload.js').then(({PictureUpload}) => {
                for (let upload of pictureUploads) {
                    new PictureUpload(upload);
                }
            });
        }

        // Collage uploads
        let collageUploads = parent.querySelectorAll('.evt-collage-upload');
        if (collageUploads.length) {
            import('./CollageUpload.js').then(({CollageUpload}) => {
                for (let upload of collageUploads) {
                    new CollageUpload(upload);
                }
            });
        }

        // Cropping
        let croppingModals = parent.querySelectorAll('.evt-crop-modal');
        if (croppingModals.length) {
            import('../Library/Cropping.js').then(({Cropping}) => {
                for (let cropModal of croppingModals) {
                    new Cropping(cropModal);
                }
            });
        }

        // Bind async forms
        let asyncForms = parent.querySelectorAll('.evt-async-form');
        if (asyncForms.length) {
            import('./AsyncForm.js').then(({AsyncForm}) => {
                for (let form of asyncForms) {
                    AsyncForm.bindEvents(form);
                }
            });
        }

        // Bind async checkboxes
        let asyncCheckboxes = parent.querySelectorAll('.evt-async-checkbox');
        if (asyncCheckboxes.length) {
            import('./AsyncCheckbox.js').then(({AsyncCheckbox}) => {
                for (let input of asyncCheckboxes) {
                    AsyncCheckbox.bind(input);
                }
            });
        }

        // Bind review events
        if (document.querySelector('.evt-review')) {
            import('./Events/Reviews.js').then(({Reviews}) => {
                Reviews.bindEvents(parent)
            });
        }

        let fieldAdder = parent.querySelector('.evt-field-adder');
        if (fieldAdder) {
            import('../Library/FieldAdder.js').then(({FieldAdder}) => {
                new FieldAdder(fieldAdder, parent.querySelector('.field-grid'));
            });
        }

        let primarySelectors = parent.querySelectorAll('.evt-primary-selector');
        if (primarySelectors.length) {
            import('./Events/PrimarySelector.js').then(({PrimarySelector}) => {
                for (let fieldset of primarySelectors) {
                    PrimarySelector.bind(fieldset);
                }
            });
        }

        // [data-modal-open]
        let modalOpenAttrEvents = parent.querySelectorAll('[data-modal-open]');
        if (modalOpenAttrEvents.length) {
            for (let button of modalOpenAttrEvents) {
                ModalOpenAttributeEvent.bind(button);
            }
        }

        let showAllScreenshotsButton = parent.querySelector('.evt-show-all-screenshots');
        if (showAllScreenshotsButton) {
            import('../Library/ShowAllButton.js').then(({ShowAllButton}) => {
                new ShowAllButton(showAllScreenshotsButton, parent.querySelector('.screenshot-grid'));
            });
        }

        let tabContainers = parent.querySelectorAll('.evt-tab-container');
        if (tabContainers.length) {
            import('./ContentTabs.js').then(({ContentTabs}) => {
                for (let tabContainer of tabContainers) {
                    ContentTabs.bind(tabContainer);
                }
            })
        }

        // Init all single image views in modal with [data-modal-image-src]
        let imageViewModals = parent.querySelectorAll('[data-modal-image-src]');
        if (imageViewModals.length) {
            import('../Library/ImageViewModal.js').then(({ImageViewModal}) => {
                for (let img of imageViewModals) {
                    ImageViewModal.bind(img);
                }
            });
        }

        // Init Ajax elements
        let ajaxElements = document.querySelectorAll('[data-ajax-post]');
        if (ajaxElements.length) {
            import('../Library/AjaxElement.js').then(({AjaxElement}) => {
                for (let elm of ajaxElements) {
                    AjaxElement.bind(elm);
                }
            });
        }

        // Init Ajax content update
        let ajaxContentUpdate = parent.querySelectorAll('[data-ajax-update-trigger]');
        if (ajaxContentUpdate.length) {
            import('./Events/AjaxContentUpdate.js').then(({AjaxContentUpdate}) => {
                for (let button of ajaxContentUpdate) {
                    AjaxContentUpdate.bind(button);
                }
            });
        }

        // Mobile Side menu
        if (parent.querySelector('.evt-sidebar-toggle')) {
            import('./Events/MobileSideMenu.js').then(({MobileSideMenu}) => {
                new MobileSideMenu(parent);
            });
        }

        // Sortable list with buttons
        let sortableLists = parent.querySelectorAll('.evt-clickable-order-change');
        if (sortableLists.length) {
            import('../Library/SortableList.js').then(({SortableList}) => {
                for (let list of sortableLists) {
                    SortableList.bind(list);
                }
            });
        }

        let inputPreviews = parent.querySelectorAll('input[data-updateinput]');
        if (inputPreviews.length) {
            import('../Library/InputPreview.js').then(({InputPreview}) => {
                for (let elm of inputPreviews) {
                    InputPreview.bind(elm, parent);
                }
            });
        }

        // Init Video elements
        let videoElements = parent.querySelectorAll('.evt-video-youtube');
        if (videoElements.length) {
            import('../Library/VideoElement.js').then(({VideoElement}) => {
                for (let elm of videoElements) {
                    new VideoElement(elm);
                }
            });
        }

        // Init Games
        let games = parent.querySelectorAll('.evt-game-loader');
        if (games.length) {
            import('../Library/GameLoader.js').then(({GameLoader}) => {
                for (let elm of games) {
                    new GameLoader(elm);
                }
            });
        }

        // Showmore
        let showMores = parent.querySelectorAll('.evt-show-more-shortcode');
        if (showMores.length) {
            for (let elm of showMores) {
                new ShowMore(elm);
            }
        }

        // Lazy section load
        let lazyContent = parent.querySelectorAll('[data-lazy-content]');
        if (lazyContent.length) {
                LazyAjaxContent.initObserver(lazyContent);
        }

        // Input ajax validation
        let ajaxValidations = parent.querySelectorAll('[data-ajax-validation]');
        if (ajaxValidations.length) {
            import('../Library/AjaxValidation.js').then(({AjaxValidation}) => {
                for (let input of ajaxValidations) {
                    new AjaxValidation(input);
                }
            });
        }

        // Simple filtering
        let listWithSimpleFilters = parent.querySelectorAll('.evt-list-with-simple-filters');
        if (listWithSimpleFilters.length) {
            import('../Library/ListWithSimpleFilters.js').then(({ListWithSimpleFilters}) => {
                for (let list of listWithSimpleFilters) {
                    new ListWithSimpleFilters(list);
                }
            });
        }

        let masterCheckbox = parent.querySelector('.evt-master-checkbox');
        if (masterCheckbox) {
            import('../Library/MasterCheckbox.js').then(({MasterCheckbox}) => {
                new MasterCheckbox(masterCheckbox, parent);
            });
        }

        let characterLimitChecker = parent.querySelector('.evt-limit-checker');
        if (characterLimitChecker) {
            import('../Library/CharacterLimitChecker.js').then(({CharacterLimitChecker}) => {
               new CharacterLimitChecker(characterLimitChecker);
            });
        }

        this.propagateClickEvents(parent);

        // Used in ex. .accordion--mobile to fix anchor links to hidden area
        this.dataTriggerForLabel(parent);

        // Content load more functions
        LoadMoreEvents.bindAll(parent);

        // Lazyload (for images and styles)
        new Lazyload(parent);

        // Bind all events that are only bound once; eg. not in any ajax loaded content
        if (!document.body.bindOnce) {
            this.bindOnce();
            document.body.bindOnce = true;
        }
    }

    static dataTriggerForLabel(parent)
    {
        for (let trigger of parent.querySelectorAll('[data-trigger-for-label]')) {
            trigger.addEventListener('click', () => {
                let targetLabel = trigger.getAttribute('data-trigger-for-label');
                let target = document.querySelector('input#' + targetLabel);

                window.location.hash = '';
                if (target && !target.checked) {
                    target.checked = true;
                }

                window.location.hash = '#' + targetLabel;
            });
        }
    }

    static propagateClickEvents(parent)
    {
        for (let container of parent.querySelectorAll('[data-propagate-click]')) {
            container.addEventListener('click', (event) => {
                if(event.target.href || event.target.closest('a[href]') ||
                    event.target.tagName === 'BUTTON' || event.target.closest('button')) {
                    return;
                }

                for (let specimen of container.querySelectorAll(container.dataset.propagateClick)) {
                    specimen.click();
                }
            });
        }
    }

    static bindOnce()
    {
        // Main navigation
        let mainNavigation = document.querySelector('.evt-main-nav');
        if (mainNavigation) {
            MainNavigation.bindEvents();
        }

        // Header search
        if (document.querySelector('.header-search')) {
            new HeaderSearch();
        }

        // 3D nav / cta on review pages
        let threeDNav = document.querySelector('.evt-three-d-cta');
        if (threeDNav) {
            import('./Events/ThreeDNav.js').then(({ThreeDNav}) => {
                new ThreeDNav();
            });
        }

        // Table of contents
        let TOCSections = document.querySelectorAll('.evt-toc-section') || [];
        for (let section of TOCSections) {
            section.addEventListener('click', (e) => {
                let target = e.target;
                if (target.classList.contains('evt-toggle-toc-dropdown')) {
                    for (let open of TOCSections) {
                        if (open !== section) {
                            open.classList.remove('section-open');
                        } else {
                            open.classList.toggle('section-open');
                        }
                    }
                }
            });
        }

        // Shows notification at bottom of the page, for example "accept cookies"
        let notificationBottomWrapper = document.querySelector('.evt-notification-bottom');
        if (notificationBottomWrapper) {
            import('./Events/BottomNotification.js').then(({BottomNotification}) => {
                new BottomNotification(notificationBottomWrapper);
            });
        }

        // Language select
        if (document.querySelector('.evt-language-change')) {
            import('../Library/LanguageMenu.js').then(({LanguageMenu}) => {
                new LanguageMenu();
            });
        }

        // Locale notify -- Continue
        for (let btn of document.querySelectorAll('.evt-locale-notify__close-banner[data-locale]')) {
            btn.addEventListener('click', () => {
                let d = new Date();
                let locale = btn.getAttribute('data-locale')
                d.setTime(d.getTime() + (24*60*60*1000));
                document.cookie = "locale_notify_checked=" + locale + ";expires=" + d.toUTCString() + ";path=/";

                // remove box
                for (let notify of document.querySelectorAll('.locale-notify')) {
                    notify.remove();
                }
            })
        }

        for (let trigger of document.querySelectorAll('.evt-ga-trigger-element')) {
            if (trigger.hasAttribute('data-name') && trigger.hasAttribute('data-value')) {
                window.bo.tagManagerTrigger.push({
                    'event': trigger.getAttribute('data-name'),
                    'eventLabel': trigger.getAttribute('data-value')
                });
            }
        }

        let showMoreBtn = document.querySelector('.evt-show-more-tipsters');
        if (showMoreBtn) {
            import('../Library/ShowMoreTipsters.js').then(({ShowMoreTipsters}) => {
                new ShowMoreTipsters(showMoreBtn, document.querySelector('.evt-tipster-stats-list-container'));
            });
        }

        for (let showAllLogosButton of document.querySelectorAll('.evt-show-all-logo')) {
            if (showAllLogosButton) {
                import('../Library/ShowAllLogo.js').then(({ShowAllLogo}) => {
                    new ShowAllLogo(showAllLogosButton);
                });
            }
        }

        // Screenshot slider
        for (let slider of document.querySelectorAll('.evt-slider-container')) {
            if (slider) {
                import('../Library/Slider.js').then(({Slider}) => {
                    new Slider(slider);
                });
            }
        }

        // Wagering calculator
        for (let wageringCalculator of document.querySelectorAll('.evt-wagering-calculator')) {
            import('../Library/WageringCalculator.js').then(({WageringCalculator}) => {
                new WageringCalculator(wageringCalculator);
            });
        }

        // Odds converter
        for (let oddsConverter of document.querySelectorAll('.evt-odds-converter')) {
            import('../Library/OddsConverter.js').then(({OddsConverter}) => {
                new OddsConverter(oddsConverter);
            });
        }

        // Footer element with the first item of toplist
        import('../Library/ToplistFirstItemFooter.js').then(({ToplistFirstItemFooter}) => {
            new ToplistFirstItemFooter();
        });

    }
}